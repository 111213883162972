/* Logo.css */

@import '../styles/global.css';

.logo {
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
  background: linear-gradient(90deg, #d80000, #ff8c8c);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: relative;
  user-select: none;
  margin-left: 20px;
}

.logo:hover {
  transform: scale(1.1);
}

.wobble {
  animation: wobbleAnimation 0.5s ease-in-out;
  transform-origin: center;
}

@keyframes wobbleAnimation {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(-10px) rotate(-20deg);
  }
  30% {
    transform: translateX(10px) rotate(20deg);
  }
  45% {
    transform: translateX(-10px) rotate(-20deg);
  }
  60% {
    transform: translateX(10px) rotate(20deg);
  }
  75% {
    transform: translateX(-10px) rotate(-20deg);
  }
  100% {
    transform: translateX(0);
  }
}
