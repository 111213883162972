/* Profile.css */
@import '../styles/global.css';

.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px); /* Subtract header and footer height */
  padding-top: 60px; /* height of header */
  padding-bottom: 40px; /* height of footer */
  background-color: var(--timberwolf);
}

.profileContainer.dark-mode {
  background-color: var(--hunter-green);
}

.mainContainer {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background: var(--timberwolf);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.mainContainer.dark-mode {
  background-color: var(--hunter-green);
  color: var(--timberwolf);
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h2 {
  color: var(--text-color); /* Updated to use the text color variable */
}

.editButton {
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-top: 10px;
  color: var(--text-color); /* Updated to use the text color variable */
}

input[type="text"],
input[type="email"],
select {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid var(--sage);
  border-radius: 4px;
  color: var(--text-color); /* Updated to use the text color variable */
}

input[type="checkbox"] {
  margin-top: 10px;
  margin-right: 5px;
}

button {
  margin-top: 20px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: var(--timberwolf);
  cursor: pointer;
  font-weight: bold;
  background-color: var(--fern-green);
}

button:hover {
  background-color: var (--sage);
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
}

.radio-group label {
  margin-right: 10px;
}

.addressGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.addressGroup label {
  flex: 1 1 100%;
}

.addressGroup input {
  flex: 1 1 calc(50% - 10px);
}
