/* dark-mode.css */

body.dark-mode {
  --background-color: #333D29;
  /* --text-color: #C2C5AA; */
  --accent-color: #656D4A;
  --hover-color: #A4AC86;
  --border-color: #414833;
  --highlight-color: #414833;
  background-color: var(--background-color);
  color: var(--text-color);
}

body.dark-mode .dark-mode-background {
  background-color: var(--background-color);
  color: var(--text-color);
}

body.dark-mode .dark-mode-border {
  border-color: var(--border-color);
}

body.dark-mode .dark-mode-accent {
  background-color: var(--accent-color);
  color: var(--text-color);
}

body.dark-mode .dark-mode-hover:hover {
  background-color: var(--hover-color);
  color: var(--text-color);
}

body.dark-mode .dark-mode-highlight {
  color: var(--highlight-color);
}

body.dark-mode .App-header {
  background-color: var(--background-color);
  color: var(--text-color);
}

body.dark-mode .App-link {
  color: var(--accent-color);
}

body.dark-mode h1, body.dark-mode h2, body.dark-mode h3, body.dark-mode h4, body.dark-mode h5, body.dark-mode h6, body.dark-mode p, body.dark-mode label {
  color: var(--text-color); /* Ensure headers and labels use the text color variable */
}

body.dark-mode input[type="text"],
body.dark-mode input[type="email"],
body.dark-mode select {
  color: var(--text-color); /* Ensure input fields use the text color variable */
}

body.dark-mode .notifications {
  background-color: var(--brunswick-green);
}

body.dark-mode .notifications h2, body.dark-mode .notifications p, body.dark-mode .notifications span {
  color: var(--timberwolf);
}

body.dark-mode .task-item, body.dark-mode .job-item, body.dark-mode .project-item {
  background-color: var(--sage);
  color: var(--hunter-green);
}

body.dark-mode .task-item.active .task-status, body.dark-mode .job-item .job-status, body.dark-mode .project-item.active {
  background-color: var(--brunswick-green);
  color: var(--timberwolf);
}

body.dark-mode .task-item.pending .task-status {
  background-color: #ffc107;
  color: var(--hunter-green);
}

body.dark-mode .task-item.completed .task-status {
  background-color: var(--fern-green);
  color: var(--timberwolf);
}
