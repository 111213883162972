/* src/components/Collaboration.css */
@import '../styles/global.css';
.teams-page {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.teams-container {
  max-width: 1200px;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chat-popup {
  position: fixed; /* Ensure it is fixed to stay at the bottom right */
  bottom: 58px; /* Adjust if necessary to position above the footer */
  right: 20px; /* Adjust if necessary */
  width: 300px;
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.chat-popup.minimized {
  height: 60px;
  overflow: hidden;
}

.chat-header {
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.chat-header-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-header .close-chat {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.chat-body {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.chat-footer {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-footer input {
  flex: 1;
  padding: 5px;
}

.chat-footer button {
  margin-left: 10px;
}

.team-container {
  margin-bottom: 3px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px;
  padding-left: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.team-container:hover {
  background-color: #e6f7ff;
  transform: translateY(-2px);
}

.team-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.team-members {
  margin-top: 10px;
  padding-left: 20px;
}

.team-member {
  margin-bottom: 5px;
}
