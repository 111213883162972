/* FeaturesSection.css */

.features {
  padding: 60px 20px;
  background-color: var(--timberwolf);
}

.features-grid {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 30%;
  text-align: center;
  background-color: var(--timberwolf);
  padding: 20px;
  border: 1px solid var(--sage);
  box-shadow: 0 4px 8px var(--brunswick-green);
}

.column h1,
.column h2,
.column p {
  margin-bottom: 10px;
}
