/* Homepage.css */

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 50px;
  background-color: var(--timberwolf);
  color: var(--hunter-green);
}

.scrolling-text {
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  width: 70%;
  animation: scrollText 10s linear infinite;
}

@keyframes scrollText {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.value-section {
  padding: 40px 20px;
  background-color: var(--sage);
  color: var(--timberwolf);
  text-align: center;
  margin: 20px 0;
}

.dynamic-testimonials {
  padding: 40px 20px;
  background-color: var(--timberwolf);
  color: var(--hunter-green);
  text-align: center;
  margin: 20px 0;
}

.testimonial-slider {
  display: flex;
  overflow: hidden;
  width: 80%;
  animation: slide 20s infinite;
}

.testimonial-item {
  min-width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }

  100% {
    transform: translateX(-400%);
  }
}

.blog-section {
  padding: 40px 20px;
  background-color: var(--sage);
  color: var(--timberwolf);
  text-align: center;
  margin: 20px 0;
}

.blog-section ul {
  list-style: none;
  padding: 0;
}

.blog-section li {
  margin: 10px 0;
}

.blog-section a {
  color: var(--hunter-green);
  text-decoration: none;
  transition: color 0.3s;
}

.blog-section a:hover {
  color: var(--timberwolf);
}