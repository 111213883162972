/* ProductDemo.css */



/* StripePayment.css */

.stripe-payment {
  padding: 60px 20px;
  background-color: var(--timberwolf);
  text-align: center;
}

.stripe-logo {
  margin-top: 20px;
  width: 150px;
}

/* Add styling to video section */
.product-demo {
  padding: 60px 20px;
  background-color: var(--timberwolf);
  text-align: center;
}

.product-demo .video-wrapper video {
  width: 80%;
  border: 5px solid var(--hunter-green);
  border-radius: 10px;
}
