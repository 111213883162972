/* IntroSection.css */

.intro {
  background-color: var(--timberwolf);
  padding: 60px 20px;
  text-align: center;
}

.intro .intro-content h1,
.intro .intro-content h3 {
  margin-bottom: 20px;
}

.intro .intro-content p {
  font-style: italic;
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.intro .cta-button {
  background-color: #6c63ff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  animation: fadeIn 2s ease-in-out;
}

/* Add animation classes */
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css';

.cta-button {
  animation-duration: 2s;
}
