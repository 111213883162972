/* src/components/billing/Billing.css */

.billing-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .billing-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .funded-amount {
    margin-bottom: 20px;
  }
  
  .funded-amount p {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .funding-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .amount-input {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
  }
  
  .card-section {
    text-align: center;
  }
  
  .add-card-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .add-card-button:hover {
    background-color: #0056b3;
  }
  
  .add-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bounce-in {
    animation: bounceIn 0.6s forwards;
  }
  
  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    60% {
      opacity: 1;
      transform: scale(1.2);
    }
    80% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  
  