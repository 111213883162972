/* Projects.css */
:root {
  --timberwolf: #C2C5AA;
  --sage: #A4AC86;
  --fern-green: #656D4A;
  --hunter-green: #414833;
  --brunswick-green: #333D29;
  --beige: #B6AD90;
  --tan: #A68A64;
  --brown: #936639;
  --dark-brown: #7F4F24;
  --deep-brown: #582F0E;
}

.projects {
  display: flex;
  flex-direction: column;
  background-color: var(--timberwolf);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.projects.dark-mode {
  background-color: var(--hunter-green);
  color: var(--timberwolf);
}

.project-container {
  display: flex;
  transition: all 0.3s ease;
  height: calc(100vh - 88px);
}

.project-list {
  margin-top: 0px;
  margin-bottom: 0px;
  flex: 0 0 150px; /* Reduced width */
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--timberwolf);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: flex 0.3s ease;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column; 
}

.project-list.dark-mode {
  background-color: var(--hunter-green);
  color: var(--timberwolf);
}

.project-list.collapsed {
  flex: 0 0 0px;
  overflow: hidden;
  opacity: 1;
  width: 0px; /* Adjust this value to ensure it's wide enough to show the toggle button */
}

.project-item {
  display: block;
  padding: 15px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  cursor: pointer;
  background: none;
  border: none;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  margin-bottom: 5px;
  color: var(--hunter-green);
}

.project-item:hover,
.project-item.active {
  background-color: var(--sage);
  color: var(--brunswick-green);
}

.project-details {
  overflow-y: auto;
  flex: 1;
  padding: 10px;
  transition: flex 0.3s ease;
  background-color: var(--timberwolf);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.project-details.dark-mode {
  background-color: var(--hunter-green);
  color: var(--timberwolf);
}

.sidebar-toggle {
  position: absolute;
  top: 5px;
  left: 150px; 
  right: 0px;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 101;
  background-color: var(--brunswick-green);
  color: var(--timberwolf);
  border: none;
  padding: 10px;
  border-radius: 0%;
  font-weight: bold;
  transition: left 0.3s ease;
}

.project-list.collapsed .sidebar-toggle {
  right: 0;
  left: 0;
}

.project-list.collapsed .project-item,
.project-list.collapsed .create-project-btn,
.project-list.collapsed .search-container {
  display: none;
}

.loading-spinner,
.loading-spinner.small {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid var(--brunswick-green);
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

.loading-spinner {
  display: none;
  width: 24px;
  height: 24px;
}

.loading-spinner.small {
  display: none;
  width: 12px;
  height: 12px;
  order: -1;
}

.spinner-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
}

.spinner {
  border: 16px solid #b8b7b7;
  border-radius: 100%;
  border-top: 16px solid #344e41ff;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.translucent {
  opacity: 0.5;
  pointer-events: none;
}

.inputText {
  width: 100%;
  padding: 12px;
  margin: 15px 0;
  border: 1px solid var(--sage);
  border-radius: 4px;
  font-size: 1em;
}

.project-list button,
.jobs-viewer button {
  padding: 8px 5px;
  background-color: var(--brunswick-green);
  color: var(--timberwolf);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.project-list button:hover,
.jobs-viewer button:hover {
  background-color: var(--hunter-green);
}

.create-project-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.create-project-popup-content {
  background-color: var(--timberwolf);
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  position: relative;
}

.create-project-popup-content h3 {
  margin-top: 0;
}

.create-project-popup-content form {
  display: flex;
  flex-direction: column;
}

.create-project-popup-content input,
.create-project-popup-content textarea {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid var(--sage);
  border-radius: 4px;
}

.create-project-popup-content textarea {
  resize: vertical;
  min-height: 80px;
  max-height: 200px;
}

.create-project-popup-content button {
  padding: 8px 16px;
  background-color: var(--brunswick-green);
  color: var(--timberwolf);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.create-project-popup-content button[type="button"] {
  background-color: var(--sage);
  color: var(--hunter-green);
  margin-left: 10px;
}

.project-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.project-header h3 {
  flex-grow: 1;
  cursor: pointer;
  margin-bottom: 0;
}

.project-title-container {
  display: flex;
  flex-direction: column;
  max-width: 85%;
  margin-bottom: 0;
}

.project-description {
  font-size: 0.9em;
  color: var(--hunter-green);
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.project-description.expanded {
  -webkit-line-clamp: unset;
}

.expand-btn {
  background: none;
  border: none;
  color: var(--brunswick-green);
  cursor: pointer;
  font-size: 0.9em;
  padding: 0;
  text-decoration: underline;
  margin-top: 5px;
  align-self: flex-start;
}

.edit-project-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: var(--brunswick-green);
  margin-left: 10px;
}

.project-buttons {
  display: flex;
  gap: 10px;
}

.pen-icon {
  display: inline-block;
  transform: rotate(90deg);
}

.edit-project-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-project-popup-content {
  background-color: var(--timberwolf);
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  position: relative;
}

.edit-project-popup-content h3 {
  margin-top: 0;
}

.edit-project-popup-content form {
  display: flex;
  flex-direction: column;
}

.edit-project-popup-content input,
.edit-project-popup-content textarea {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid var(--sage);
  border-radius: 4px;
}

.edit-project-popup-content button {
  padding: 8px 16px;
  background-color: var(--brunswick-green);
  color: var(--timberwolf);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.edit-project-popup-content button[type="button"] {
  background-color: var(--sage);
  color: var (--hunter-green);
  margin-left: 10px;
}

.filter-icon {
  cursor: pointer;
  font-size: 1.5em;
  position: relative;
}

.sort-popup {
  display: flex;
  flex-direction: column;
  background: var (--timberwolf);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba (0, 0, 0, 0.1);
  position: absolute;
  top: 40px; /* Adjust this to ensure it's directly under the filter icon */
  left: 0;
  z-index: 1000;
}

.project-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column;
  width: 100%;
}

.create-project-btn {
  width: 100%;
  margin-top: 35px;
  margin-bottom: 15px;
  cursor: pointer;
  border: none;
  background-color: var(--brunswick-green);
  color: var(--timberwolf);
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  margin-left: 10px;
}

.search-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-left: 5px;
}

.search-container input[type="text"] {
  flex: 1;
  display: flex;
  width: 100%;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.delete-project-btn {
  background: none;
  border: none;
  color: #ff0000;
  font-size: 1.2em;
  cursor: pointer;
  margin-left: -10px;
}

.resizable-textarea {
  resize: vertical;
  min-height: 80px;
  max-height: 200px;
}

.sort-options {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.create-first-project {
  text-align: center;
  padding: 20px;
}

.create-first-project h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.create-first-project p {
  font-size: 16px;
  margin-bottom: 10px;
}

.create-first-project button {
  margin-top: 20px;
}

.fixed-bottom {
  position: fixed;
  bottom: 10px;
  width: 80%;
  text-align: center;
}
