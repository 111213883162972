/* src/components/Settings.css */

.settings-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .settings-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .settings-section {
    margin-bottom: 20px;
  }
  
  .settings-section h2 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .setting-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .setting-item label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .setting-item select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  