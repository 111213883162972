/* UserDashboard.css */

@import '../styles/global.css';

.App {
  padding: 20px;
}

h1, h2 {
  color: #333;
  cursor: pointer;
}

h2.clickable:hover {
  text-decoration: underline;
}

.dashboard {
  padding: 20px;
}

.stats {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 20px;
}

.stat-item {
  flex: 1 1 200px;
  margin: 10px;
}

.charts {
  display: flex;
  justify-content: space-around;
  margin: 50px 0;
}

.chart-container {
  width: 45%;
  height: 300px;
  margin-bottom: 20px;
}

.chart-container h2 {
  text-align: left;
}

.expanded-chart-container {
  display: flex;
  flex-wrap: wrap;
}

.expanded-list {
  flex: 1;
  margin-left: 20px;
}

.expanded-list h2 {
  text-align: left;
}

.expanded-list ul {
  max-height: 300px;
  overflow-y: auto;
}

.project-lists {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.project-list-item {
  flex: 1;
  margin: 10px;
}

.scrollable-container {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.project-lists h2 {
  text-align: center;
}
