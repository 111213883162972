/* index.css */

:root {
  --timberwolf: #C2C5AA;
  --sage: #A4AC86;
  --fern-green: #656D4A;
  --hunter-green: #414833;
  --brunswick-green: #333D29;
  --beige: #B6AD90;
  --tan: #A68A64;
  --brown: #936639;
  --dark-brown: #7F4F24;
  --deep-brown: #582F0E;
}

body {
  background-color: var(--timberwolf);
  color: var(--hunter-green);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.task-list {
  margin-top: 20px;
  list-style-type: none;
  padding-left: 10px;
}

.task-item {
  display: flex;
  align-items: center;
  padding: 0px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: var(--sage);
}

.task-item.active .loading-spinner.small {
  display: inline-block;
}

.task-status {
  padding: 6px 12px;
  border-radius: 4px;
  color: var(--timberwolf);
  font-weight: bold;
  display: inline-block;
  margin-right: 15px;
}

.task-name {
  flex-grow: 1;
}

.task-item.active .task-status {
  background-color: var(--brunswick-green);
}

.task-item.pending .task-status {
  background-color: #ffc107;
  color: var(--hunter-green);
}

.task-item.completed .task-status {
  background-color: var(--fern-green);
}

/* Add this to ensure text color visibility in dark mode */
body.dark-mode {
  color: var(--text-color);
}

