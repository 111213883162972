/* Signup.css */

@import '../styles/global.css';

.signUpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--timberwolf);
}

.signUpForm {
  width: 100%;
  max-width: 600px;
  background: var(--timberwolf);
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.signUpForm h2 {
  margin-bottom: 20px;
  text-align: center;
  color: var(--hunter-green);
}

.signUpForm label {
  display: block;
  color: var(--hunter-green);
  margin-bottom: 5px;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nameContainer > div {
  width: 48%;
}

.signUpForm input[type="email"], 
.signUpForm input[type="password"], 
.signUpForm input[type="text"], 
.signUpForm select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--sage);
  border-radius: 4px;
}

.signUpForm input[type="checkbox"] {
  margin-right: 5px;
}

.errorMessage {
  color: red;
  margin-bottom: 15px;
}

.signUpForm button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: var(--timberwolf);
  cursor: pointer;
  font-weight: bold;
  background-color: var(--fern-green);
}

.signUpForm .emailSignUpBtn {
  margin-bottom: 10px;
}

.signUpForm .emailSignUpBtn:hover {
  background-color: var(--sage);
}

.socialSignInBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  color: var(--timberwolf);
  cursor: pointer;
  font-weight: bold;
}

.socialSignInBtn img {
  width: 24px;
  margin-right: 8px;
}

.socialSignInBtn.google {
  background-color: #426ebe;
}

.socialSignInBtn.google:hover {
  background-color: #284376;
}

.or-separator {
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
  color: var(--hunter-green);
}

.termsAndConditions {
  display: block;
  margin-top: 10px;
}

.termsAndConditions label {
  user-select: none;
}

.signUpForm a {
  color: blue;
  text-decoration: none;
}

.signUpForm a:visited {
  color: blue;
}

footer {
  width: 100%;
  background-color: var(--hunter-green);
  color: var(--timberwolf);
  text-align: center;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
}
