/* src/components/Notifications.css */

.notifications {
  padding: 20px;
  background-color: var(--timberwolf);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.notifications h2 {
  margin-bottom: 20px;
  color: var(--hunter-green);
}

.notifications ul {
  list-style: none;
  padding: 0;
}

.notifications li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.notifications li:last-child {
  border-bottom: none;
}

.notifications p {
  margin: 0;
  color: var(--hunter-green);
}

.notifications span {
  display: block;
  margin-top: 5px;
  color: #999;
  font-size: 0.9em;
}
