/* global.css */

:root {
  --timberwolf: #C2C5AA;
  --sage: #A4AC86;
  --fern-green: #656D4A;
  --hunter-green: #414833;
  --brunswick-green: #333D29;
}

/* Add this to ensure text color visibility in dark mode */
body.dark-mode {
  color: var(--text-color);
}

body {
  font-family: 'Inter', sans-serif;
  color: var(--brunswick-green);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}


.container {
  padding: 60px 20px;
  background-color: var(--timberwolf);
}

.text-center {
  text-align: center;
}

.row, .features-grid, .logos-grid, .testimonials {
  display: flex;
  justify-content: space-between;
}

.row {
  justify-content: center;
  gap: 20px;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
}

button, .cta-button, .socialSignInBtn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

button:hover, .cta-button:hover, .socialSignInBtn:hover {
  background-color: var(--sage);
  color: var(--text-color); /* Ensure hover text color is visible */
}

.cta-button {
  background-color: #6c63ff;
  color: #fff;
}

.socialSignInBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
  color: var(--timberwolf);
}

.socialSignInBtn img {
  width: 24px;
  margin-right: 8px;
}

input[type="text"], input[type="email"], input[type="password"], select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--sage);
  border-radius: 4px;
}

input[type="checkbox"] {
  margin-right: 5px;
}

.errorMessage {
  color: red;
  margin-bottom: 15px;
}

.spinner-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.851);
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 16px solid #b8b7b7;
  border-radius: 100%;
  border-top: 16px solid #344e41ff;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background: #f9f9f9;
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
}

li.completed {
  text-decoration: line-through;
}

.column, .testimonial-item {
  width: 30%;
  text-align: center;
  background-color: var(--timberwolf);
  padding: 20px;
  border: 1px solid var (--sage);
  box-shadow: 0 4px 8px var(--brunswick-green);
}

.column h1, .column h2, .column p, .testimonial-item h1, .testimonial-item h2, .testimonial-item p {
  margin-bottom: 10px;
}

