/* UserLogin.css */

@import '../styles/global.css';

html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.userLoginContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--timberwolf);
  padding: 20px;
  box-sizing: border-box;
}

.userLoginForm {
  background: var(--timberwolf);
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.userLoginForm h2 {
  margin-bottom: 20px;
  text-align: center;
  color: var(--hunter-green);
}

.userLoginForm label {
  display: block;
  color: var(--hunter-green);
  margin-bottom: 5px;
}

.userLoginForm input[type="email"], .userLoginForm input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--sage);
  border-radius: 4px;
}

.userLoginForm button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: var(--timberwolf);
  cursor: pointer;
  font-weight: bold;
  background-color: var(--fern-green);
}

.userLoginForm button:hover {
  background-color: var(--sage);
}

.forgotPasswordBtn {
  background: none;
  border: none;
  color: var(--fern-green);
  cursor: pointer;
  margin-top: 10px;
  display: block;
  text-align: right;
}

.forgotPasswordBtn:hover {
  color: var(--sage);
}

.errorMessage {
  color: red;
  margin-bottom: 15px;
}

.googleSignInBtn {
  background-color: #db4437;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  border: none;
  border-radius: 4px;
  color: var(--timberwolf);
  cursor: pointer;
  font-weight: bold;
}

.googleSignInBtn:hover {
  background-color: #b03024;
}

.googleSignInBtn img {
  width: 24px;
  margin-right: 8px;
}

.googleSignInBtn span {
  color: var(--timberwolf);
  font-weight: normal;
}
