/* Jobs.css */

.job-status-tabs {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .job-status-tabs button {
    background-color: var(--sage);
    color: var(--hunter-green);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .job-status-tabs button:hover,
  .job-status-tabs button.active {
    background-color: var(--fern-green);
    color: var(--timberwolf);
  }
  
  .jobs-viewer,
  .jobs-container {
    list-style: none;
    padding: 0;
    border: none;
    border-radius: 8px;
    padding: 10px;
    background-color: var(--timberwolf);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .job-item {
    cursor: pointer;
    margin-bottom: 20px;
    padding: 20px;
    border: none;
    border-radius: 8px;
    background-color: var(--timberwolf);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .job-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .job-item .job-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .job-title, .job-status {
    font-weight: bold;
    font-size: 1.1em;
  }
  
  .job-item .job-status {
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
    display: inline-block;
  }
  
  .predefined-jobs {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .job-options-container {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the start */
    margin-bottom: 20px;
  }
  
  .add-job-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .add-job-popup-content {
    background-color: var(--timberwolf);
    padding: 20px;
    border-radius: 8px;
    width: 600px; /* Adjust width */
    max-width: 90%;
    position: relative;
    max-height: 90%; /* Adjust height */
    overflow-y: auto; /* Add scroll if content overflows */
  }
  
  .job-options-container input[type="text"] {
    padding: 8px;
    border: 1px solid var(--sage);
    border-radius: 4px;
  }
  
  .job-options-container label {
    margin-right: 10px;
  }
  
  .job-options-container button {
    padding: 8px 16px;
    background-color: var(--brunswick-green);
    color: var(--timberwolf);
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .job-status-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    overflow-x: auto; /* Allow horizontal scrolling */
  }
  
  .job-status-container button {
    padding: 8px 16px;
    background-color: var (--sage);
    color: var (--hunter-green);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .job-status-container button.active {
    background-color: var (--brunswick-green);
    color: var (--timberwolf);
  }
  
  .job-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .job-buttons {
    display: flex;
    gap: 10px;
  }
  
  .pause-job-btn,
  .play-job-btn,
  .delete-job-btn {
    background: none;
    border: none;
    color: #ff0000;
    font-size: 1.2em;
    cursor: pointer;
  }
  