/* Tasks.css */

.task-list {
    margin-top: 20px;
    list-style-type: none;
    padding-left: 10px;
  }
  
  .task-item {
    display: flex;
    align-items: center;
    padding: 0px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: var(--sage);
  }
  
  .task-item.active .loading-spinner.small {
    display: inline-block;
  }
  
  .task-status {
    padding: 6px 12px;
    border-radius: 4px;
    color: var(--timberwolf);
    font-weight: bold;
    display: inline-block;
    margin-right: 15px;
  }
  
  .task-name {
    flex-grow: 1;
  }
  
  .task-item.active .task-status {
    background-color: var(--brunswick-green);
  }
  
  .task-item.pending .task-status {
    background-color: #ffc107;
    color: var(--hunter-green);
  }
  
  .task-item.completed .task-status {
    background-color: var(--fern-green);
  }
  