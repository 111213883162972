/* App.css */

:root {
  --timberwolf: #C2C5AA;
  --sage: #A4AC86;
  --fern-green: #656D4A;
  --hunter-green: #414833;
  --brunswick-green: #333D29;
  --beige: #B6AD90;
  --tan: #A68A64;
  --brown: #936639;
  --dark-brown: #7F4F24;
  --deep-brown: #582F0E;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--hunter-green);
  color: var(--timberwolf);
}

.App-link {
  color: var(--sage);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.851);
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border-top: 16px solid var(--fern-green);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  flex-shrink: 0;
}

.footer {
  flex-shrink: 0;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding-top: 60px; /* height of header */
  padding-bottom: 40px; /* height of footer */
  position: relative;
  z-index: 1; /* Ensure the content appears below the header and above the footer */
}

.form-group {
  margin-bottom: 5px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--hunter-green); /* Ensure label text is visible */

}

/* Add this to ensure text color visibility in dark mode */
body.dark-mode .App-header {
  color: var(--text-color);
}

body.dark-mode .form-group label {
  color: var(--text-color);
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="email"],
.form-group input[type="file"],
.form-group select {
  width: 90%;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid var(--sage);
  border-radius: 4px;
}

.form-group input[type="radio"],
.form-group input[type="checkbox"] {
  margin-right: 10px;
}

.tooltip-icon {
  margin-left: 5px;
  cursor: pointer;
  font-size: 1.2em;
  vertical-align: middle;
}

.tooltip-icon:hover {
  color: var(--fern-green);
}

.tooltip-icon .MuiTooltip-tooltip {
  font-size: 0.8em;
}
