/* Header.css */

@import '../styles/global.css';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--hunter-green);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  color: var(--timberwolf);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 20px;
  height: 60px;
  font-family: Arial, sans-serif;
  z-index: 1000;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
  flex-shrink: 0;
}

.line {
  width: 100%;
  height: 2px;
  background-color: var(--timberwolf);
}

.logo {
  margin-left: 10px;
}

.icons {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  background-color: var(--timberwolf);
  padding: 60px 20px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1001; /* Ensure the side menu appears above the header and footer */
  border-radius: 0 10px 10px 0;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

.side-menu.open {
  transform: translateX(0);
}

.side-menu a {
  display: block;
  margin: 5px;
  color: var(--hunter-green);
  text-decoration: none;
  text-align: left;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--sage);
  margin: 10px 0;
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 34px;
}
