/* Footer.css */

@import '../styles/global.css';

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--hunter-green);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  color: var(--timberwolf);
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 10px;
  text-align: center;
  z-index: 1000;
}

.link-button {
  background: none;
  border: none;
  color: var(--timberwolf);
  text-decoration: none;
  margin: 0 10px;
  cursor: pointer;
  font-size: inherit;
}

.link-button:hover {
  text-decoration: underline;
  color: var(--sage);
}
