/* Terms.css */

@import '../styles/global.css';

.termsContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.termsContainer h1 {
  text-align: center;
  margin-bottom: 20px;
}

.termsContainer h2 {
  margin-top: 20px;
}

.termsContainer p {
  line-height: 1.6;
}
